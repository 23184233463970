import React, { useState } from 'react';
import { Book, Briefcase, School, GraduationCap, Youtube, Route, Heart, CircleDollarSign, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const categorizedTypes = {
  educators: [
    {
      title: "Language Teachers",
      icon: Book,
      image: "/assets/images/Zokalo-English-Lesson.png",
      features: [
        "Organize Live Coaching Sessions",
        "Schedule and receive payments for future classes",
        "Curate content for your students",
        "Create learning activities and subscriptions"
      ]
    },
    {
      title: "School Teachers",
      icon: School,
      image: "/assets/images/Zokalo-field-trip-prices.png",
      features: [
        "Manage registration for field trips",
        "Promote school events",
        "Create payment pages for class materials",
        "Collect signatures for activities easily"
      ]
    },
    {
      title: "Business Consultants",
      icon: Briefcase,
      image: "/assets/images/Zokalo-business-coach.png",
      features: [
        "Create private learning portals for clients",
        "Offer subscription-based Q&A forums",
        "Curate relevant content for clients",
        "Generate detailed progress reports"
      ]
    },
    {
      title: "Professors",
      icon: GraduationCap,
      image: "/assets/images/Professors-Monetize.png",
      features: [
        "Offer online and face-to-face tutoring services",
        "Share research papers and work",
        "Monetize practice tests and learning materials",
        "Engage with corporate clients"
      ]
    },
    {
      title: "Education Influencers",
      icon: Youtube,
      image: "/assets/images/Zokalo-education-influencer.png",
      features: [
        "Create and sell multiple products",
        "Bundle educational products",
        "Create targeted landing pages",
        "Measure engagement and conversions"
      ]
    }
  ],
  features: [
    {
      title: "Learning Paths",
      icon: Route,
      image: "/assets/images/Zokalo-paths.png",
      features: [
        "Bundle products into learning sequences",
        "Offer bundled pricing",
        "Provide completion certificates",
        "Track user progress"
      ]
    },
    {
      title: "Easy Payments",
      icon: CircleDollarSign,
      image: "/assets/images/Zokalo-sales-dashboard.png",
      features: [
        "Receive daily payouts to your bank account",
        "Accept international payments",
        "Manage refunds and chargebacks",
        "Offer payment plans"
      ]
    },
    {
      title: "Instructor Profile",
      icon: Heart,
      image: "/assets/images/Zokalo-Profile-Page.png",
      features: [
        "Showcase your expertise and offerings",
        "Collaborate with other instructors",
        "Display upcoming sessions and courses",
        "Share your profile on social media"
      ]
    }
  ]
};

const Modal = ({ isOpen, onClose, children }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
      >
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          onClick={(e) => e.stopPropagation()}
          className="bg-white rounded-lg overflow-hidden relative max-w-4xl w-full max-h-[90vh]"
        >
          {children}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white bg-brand-primary rounded-full p-2 hover:bg-brand-primary-dark transition-colors duration-300"
          >
            <X size={24} />
          </button>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

const FeatureCard = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
        whileHover={{ y: -5 }}
      >
        <div 
          className="h-48 overflow-hidden"
          onClick={() => setIsModalOpen(true)}
        >
          <img src={item.image} alt={item.title} className="w-full h-full object-cover transition-transform duration-300 hover:scale-110" />
        </div>
        <div className="p-6">
          <h3 className="font-heading text-xl font-semibold text-brand-primary mb-4 flex items-center">
            <item.icon className="text-brand-highlight mr-2" size={24} />
            {item.title}
          </h3>
          <ul className="space-y-2">
            {item.features.map((feature, index) => (
              <li key={index} className="flex items-start">
                <span className="text-brand-highlight mr-2">•</span>
                <span className="font-body text-sm text-brand-gray">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </motion.div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img src={item.image} alt={item.title} className="w-full h-auto" />
      </Modal>
    </>
  );
};

const FeatureShowcase = () => {
  const [currentCategory, setCurrentCategory] = useState('educators');

  return (
    <div className="bg-brand-background py-16 px-6 md:px-12">
      <div className="max-w-7xl mx-auto">
        <h2 className="font-heading text-3xl md:text-4xl font-light text-brand-primary mb-12 text-center">
          Empower Your Teaching Journey
        </h2>
        
        <div className="flex justify-center mb-12">
          <button
            onClick={() => setCurrentCategory('educators')}
            className={`px-6 py-3 font-body text-lg font-medium transition-colors duration-300 ${
              currentCategory === 'educators' ? 'bg-brand-primary text-white' : 'bg-white text-brand-primary'
            } rounded-l-lg hover:bg-brand-primary hover:text-white`}
          >
            Educators
          </button>
          <button
            onClick={() => setCurrentCategory('features')}
            className={`px-6 py-3 font-body text-lg font-medium transition-colors duration-300 ${
              currentCategory === 'features' ? 'bg-brand-primary text-white' : 'bg-white text-brand-primary'
            } rounded-r-lg hover:bg-brand-primary hover:text-white`}
          >
            Features
          </button>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={currentCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            {categorizedTypes[currentCategory].map((item, index) => (
              <FeatureCard key={item.title} item={item} />
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default FeatureShowcase;