import React from 'react';
import { ArrowRight } from 'lucide-react';

const EducatorValueSection = () => (
  <div className="bg-white py-16 px-6 md:px-12">
    <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 md:pr-12 mb-8 md:mb-0">
        <h2 className="font-heading text-3xl md:text-4xl font-light text-brand-primary mb-6">
          Transform Your Expertise into a Thriving Digital Asset
        </h2>
        <p className="font-body text-lg text-brand-gray mb-6">
          Your knowledge, materials, and student relationships are gold. Zokalo is the platform where you can showcase, grow, and monetize your educational content, turning your hard work into a valuable, scalable business asset.
        </p>
        <ul className="font-body text-brand-gray mb-8 space-y-2">
          <li>✓ Centralize all your educational materials</li>
          <li>✓ Build and nurture your student community</li>
          <li>✓ Easily monetize your expertise</li>
          <li>✓ Scale your impact and income</li>
        </ul>
        <button 
          className="bg-brand-highlight hover:bg-brand-highlight/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300 inline-flex items-center"
          onClick={() => window.open('https://buy.stripe.com/28oaGc1yYax9cKYaFc', '_blank')}
        >
          Start Building Your Educational Empire
          <ArrowRight className="ml-2" size={20} />
        </button>
      </div>
      <div className="md:w-1/2">
        <img 
          src="/assets/images/Zokalo-Teacher-assets.png" 
          alt="Educator's work valued and leveraged" 
          className="rounded-lg shadow-lg w-full h-auto"
        />
      </div>
    </div>
  </div>
);

export default EducatorValueSection;