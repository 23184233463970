import React from 'react';
import ValueProposition from './ValueProposition';

function App() {
  const handleGetDemo = () => {
    window.open('https://calendly.com/apprendo/onboarding-with-apprendo', '_blank');
  };

  const handleGetStarted = () => {
    window.open('https://buy.stripe.com/28oaGc1yYax9cKYaFc', '_blank');
  };

  return (
    <div className="App min-h-screen bg-[#F3F4F5] flex flex-col">
      <header className="w-full bg-white shadow-md p-4 flex justify-between items-center">
        <img src='/assets/images/zokalo.svg' alt="Zokalo Logo" className="h-8" />
        <div className="flex items-center space-x-4">
          <button 
            onClick={handleGetDemo}
            className="px-4 py-2 text-[#102340] font-medium hover:bg-[#F3F4F5] rounded-lg transition-colors duration-300"
          >
            Get a Demo
          </button>
          <button 
            onClick={handleGetStarted}
            className="px-4 py-2 bg-[#EB3B38] text-white font-medium rounded-lg hover:bg-[#EB3B38]/90 transition-colors duration-300"
          >
            Get Started
          </button>
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center p-4">
        <ValueProposition />
      </main>
    </div>
  );
}

export default App;