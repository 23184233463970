import React from 'react';
import { Book, Calculator, Briefcase, School, GraduationCap, Youtube } from 'lucide-react';

const TrustedBy = () => {
  const trustedGroups = [
    { name: "English Teachers", icon: Book },
    { name: "Math Teachers", icon: Calculator },
    { name: "Business Consultants", icon: Briefcase },
    { name: "School Teachers", icon: School },
    { name: "Professors", icon: GraduationCap },
    { name: "Education Influencers", icon: Youtube }
  ];

  return (
    <div className="bg-white py-12 px-6 md:px-12">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-center text-2xl md:text-3xl font-bold text-brand-subheadline mb-8">
          Trusted by Educators Worldwide
        </h2>
        <div className="flex flex-wrap justify-center items-center gap-4 mb-8">
          {trustedGroups.map(({ name, icon: Icon }, index) => (
            <div key={index} className="flex items-center bg-brand-background rounded-full px-4 py-2">
              <Icon className="text-brand-highlight mr-2" size={20} />
              <span className="text-brand-subheadline font-medium">{name}</span>
            </div>
          ))}
        </div>
        <p className="text-center text-lg text-brand-paragraph">
          Join thousands of education professionals who trust Zokalo to grow their business
        </p>
      </div>
    </div>
  );
};

export default TrustedBy;