import React, { useEffect, useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import FeatureShowcase from './FeatureShowcase';
import TrustedBy from './TrustedBy';
import EducatorValueSection from './EducatorValueSection';


const Hero = () => (
  <div className="bg-brand-primary text-white py-16 px-6 md:px-12">
    <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <h1 className="font-heading text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
        Teach, Manage, Grow: 100% Your Way
        </h1>
        <p className="font-body text-xl md:text-2xl mb-8">
          Own your relationship with your clients and students.
        </p>
        <button 
          className="bg-brand-highlight hover:bg-brand-highlight/90 text-white font-bold py-4 px-8 rounded-lg transition duration-300 flex items-center justify-center text-xl"
          onClick={() => window.open('https://buy.stripe.com/28oaGc1yYax9cKYaFc', '_blank')}
        >
          Get Started
          <ArrowRight className="ml-2" size={24} />
        </button>
      </div>
      <div className="md:w-1/2">
        <img 
          src="/assets/images/Zokalo-hero-education.png" 
          alt="Teacher interacting with students" 
          className="rounded-lg shadow-lg w-full h-auto"
        />
      </div>
    </div>
  </div>
);

const ECommerceSection = () => (
  <div className="bg-white py-16 px-6 md:px-12">
    <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <img 
          src="/assets/images/Zokalo-launch.png" 
          alt="Quick eCommerce setup" 
          className="rounded-lg shadow-lg w-full h-auto"
        />
      </div>
      <div className="md:w-1/2 md:pl-12">
        <h2 className="font-heading text-3xl md:text-4xl font-light text-brand-primary mb-6">
          Launch your Own Education eCommerce and student database in less than 5 minutes.
        </h2>
        <p className="font-body text-lg text-brand-gray mb-8">
          With Zokalo, you can quickly set up your online education platform and start selling your courses and materials. Our intuitive interface makes it easy to manage your students and grow your business.
        </p>
        <button 
          className="bg-brand-highlight hover:bg-brand-highlight/90 text-white font-bold py-3 px-6 rounded-lg transition duration-300 inline-flex items-center"
          onClick={() => window.open('https://buy.stripe.com/28oaGc1yYax9cKYaFc', '_blank')}
        >
          Get Started Now
          <ArrowRight className="ml-2" size={20} />
        </button>
      </div>
    </div>
  </div>
);

const SeparatorSection = () => (
  <div className="bg-brand-primary text-white py-16 px-6 md:px-12 text-center">
    <div className="max-w-4xl mx-auto">
      <p className="font-heading text-2xl md:text-3xl font-light leading-relaxed">
        Your learners love you. Make it easy for them to show their appreciation with quick, easy payments for all your hard work.
      </p>
    </div>
  </div>
);


const ValueProposition = () => {
  const pricingTableRef = useRef(null);

  useEffect(() => {
    // Load Stripe Pricing Table script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Add custom CSS to make the pricing table wider
    const style = document.createElement('style');
    style.textContent = `
      .stripe-pricing-table-wrapper {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        overflow-x: auto;
      }
      stripe-pricing-table {
        width: 100%;
        min-width: 800px;
      }
    `;
    document.head.appendChild(style);

    // Configure the pricing table once the script is loaded
    script.onload = () => {
      if (pricingTableRef.current) {
        pricingTableRef.current.addEventListener('load', () => {
          pricingTableRef.current.layout = 'columns';
          pricingTableRef.current.style.setProperty('--base-width', '300px');
        });
      }
    };

    return () => {
      // Cleanup: remove the script and style when the component unmounts
      document.body.removeChild(script);
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div className="max-w-[1400px] mx-auto bg-brand-background">
      <Hero />
      <TrustedBy />
      <ECommerceSection />
      <SeparatorSection />
      <EducatorValueSection />
      <div className="p-6 md:p-12">
        <FeatureShowcase />
        <button 
          className="w-full max-w-xl mx-auto bg-brand-highlight hover:bg-brand-highlight/90 text-white font-bold py-4 px-8 rounded-lg transition duration-300 flex items-center justify-center text-xl"
          onClick={() => window.open('https://buy.stripe.com/28oaGc1yYax9cKYaFc', '_blank')}
        >
          Start Your Free Trial Today
          <ArrowRight className="ml-2" size={24} />
        </button>
      </div>

      {/* Testimonial Section */}
      <div className="bg-white p-6 md:p-12 border-t border-gray-200">
        <div className="flex flex-col md:flex-row items-center gap-8 max-w-4xl mx-auto">
          <div className="md:w-1/2">
            <img src="/assets/images/Claire-learn.png" alt="Instructor using Zokalo" className="rounded-lg shadow-md w-full h-auto" />
          </div>
          <div className="md:w-1/2">
            <p className="text-xl text-brand-paragraph mb-4">
              "With Zokalo, I've streamlined my tutoring and coaching services. I can easily manage payments, schedule sessions, and maintain great relationships with my clients. It's helped me grow my business and focus on what I do best - teaching!"
            </p>
            <p className="text-lg font-semibold text-brand-subheadline">
              - Sarah T., Language Instructor and Coach
            </p>
          </div>
        </div>
      </div>

      {/* Stripe Pricing Table Section */}
      <div className="bg-brand-background p-6 md:p-12 border-t border-gray-200">
        <h2 className="font-heading text-3xl font-bold text-brand-primary mb-8 text-center">Simple, Transparent Pricing</h2>
        <div className="stripe-pricing-table-wrapper">
          <stripe-pricing-table
            ref={pricingTableRef}
            pricing-table-id="prctbl_1PfTc9484m9mvDxjcGZclVzO"
            publishable-key="pk_live_TLrh98J6fj18J5KLhNMWwbTr"
          ></stripe-pricing-table>
        </div>
      </div>

      <div className="bg-white p-6 md:p-12 border-t border-gray-200 text-center">
        <p className="font-body text-xl text-brand-gray mb-6 max-w-3xl mx-auto">
          Your learners love you. Make it easy for them to show their appreciation with quick, easy payments for all your hard work.
        </p>
        <button className="bg-brand-highlight hover:bg-brand-highlight/90 text-white font-bold py-4 px-8 rounded-lg transition duration-300 inline-flex items-center text-xl">
          Start Your Free Trial Today
          <ArrowRight className="ml-2" size={24} />
        </button>
      </div>
    </div>
  );
};

export default ValueProposition;